.dynamicPage-content {

  .startup-title-content {
    position: relative;
    .opacity {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: transparent linear-gradient(180deg, var(--color-484848) 0%, var(--color-242424) 100%) 0% 0% no-repeat padding-box;
      opacity: 0.5;
    }

    .opacity-noimage {
      @extend .opacity;
      opacity: 0.75 !important;
      background: transparent linear-gradient(180deg, var(--color-0130a4) 0%, var(--color-181c49) 100%) 0% 0% no-repeat padding-box !important;
    }


    .startup-page-title {
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0px;
      color: white;
      height: 100%;
      a {
        color: white;
      }

      .title-content {
        height: 100%;
        .title {
          text-align: left;
          font: normal normal normal 44px/48px Open Sans;
          letter-spacing: 0px;
        }
        .description {
          text-align: justify;
        }

      }

    }

  }
}

.startup-dash-count-content {
  min-height: 280px;
  padding: 30px;
  .label-value {
    color: var(--color-004a8d);
    font: normal normal bold 48px/52px Open Sans;
    margin-right: 10px;
  }

  p {
    width: 90px;
  }
}

.startup-group-card {
  position: relative;
  height: 180px;
  .card-content {
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 30px;
    color: white;
    height: 180px;
    .title {
      font: normal normal bold 41px/56px Open Sans;
      font-weight: bold;
    }
    .count-label {
      font: normal normal bold 41px/56px Open Sans;
      font-weight: bold;
    }
  }

  .opacity {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: transparent linear-gradient(180deg, var(--color-0130a4) 0%, var(--color-181c49) 100%) 0% 0% no-repeat padding-box;
    opacity: 0.75;
    border-radius: 10px;
  }

  .card-image {
    height: 180px;
    img {
      height: 180px;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 3px 6px #4848483D;
    }

    .loader {
      border-radius: 10px;
    }
  }

}


.startup-card {
  border: 1px solid #BFC4CC;

  .contact-field {
    font: normal normal bold 16px/18px Open Sans;
    margin-right: 15px;
  }

  .about-text {
    font-weight: bold;
    height: 160px;
    overflow-x: hidden;
    overflow-y: auto;
  }

}
