.Container {

  &.InlineBox {
    overflow-x: scroll;
    padding-right: 50px !important;

    .Section {

      padding-right: 50px;
      display: -webkit-inline-box;

      .component {}
    }
  }

}
