

.space-around-center {

  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-around;
  align-items: center;


  .col{
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
    padding: 0 !important;
  }

}

.v-center-flex {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
