.dynamicPage-content {
  .single-page-title {
    .page-title {
      text-align: left;
      font: normal normal normal 44px/48px Open Sans;
      letter-spacing: 0px;
      color: var(--color-004a8d);
    }

    .image-info-box {
      border-radius: 10px;
      border: 1px solid #BFC4CC;

      img {
        border-radius: 10px;
        height: 280px;
      }
    }

    .page-description {
      text-align: justify;
    }
  }
}


.page-content {

  margin-bottom: $pageMarginBottom;

  .grid {
    margin: -4px !important;
  }

  .page-title {
    color: var(--color-004a8d);
    font-weight: 600;
    text-align: justify;
    font: normal normal 300 28px/32px Open Sans;
    padding-top: 35px;
    padding-bottom: 20px;

    .subtitle {
      font-size: 18px;
      color: var(--color-242424);
      text-transform: uppercase;
      margin-top: 10px;

      .btn {
        min-height: 26px;
      }
    }


  }

  .subtitle-blue {
    color: var(--color-004a8d);
    font-weight: 600;
    text-align: left;
    font-size: 18px !important;
    letter-spacing: 0px;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;

    img {
      height: 22px;
    }
  }

  .text-blue-normal {
    color: var(--color-004a8d);
    text-align: left;
    font-weight: normal;
    letter-spacing: 0px;
    opacity: 1;
  }


  .page-details {
    padding-bottom: 50px;

    .photo {
      text-align: center;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;

      img {
        width: 100%;
        object-fit: cover;
      }

      figcaption {
        font-style: italic;
        text-align: center;
      }
    }

    .text {
      margin-top: 40px;
      color: var(--color-484848);
      text-align: justify;
    }

    .gallery-content {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;
    }


    .tag-content {
      font-style: italic;

      a {
        color: var(--color-004a8d);
        cursor: pointer;
      }

    }

  }

}
