.p-multiselect ,.p-dropdown {
  width: 100%;
  padding: 4.5px 5px;
  margin: 0 !important;
  box-sizing: border-box;
  color: var(--color-7784ac) !important;
  border: 1px solid var(--color-7784ac);
  border-radius: 0px !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  font-size: 18px !important;
  line-height: 1.42857143 !important;
}

.p-overlay {
  left: -0.5px !important;
  right: -0.5px !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--color-7784ac) !important;
  opacity: 1 !important;
  /* Firefox */
}

.p-multiselect-panel {
  border: 1px solid var(--color-7784ac) !important;
  border-radius: 0px !important;
  border-bottom: solid 3px var(--color-f6be00) !important;
}
