.contact-content{

  padding-top: 50px;

  .contact-title{
    text-align: left;
    font: normal normal normal 41px/48px Open Sans;
    letter-spacing: 0px;
    color: #004A8D;
    opacity: 1;
  }


  .contact-text {
    text-align: justify;
  }

  .contact-icon-phone{
    color: #004A8D;
    opacity: 1;
  }

  .contact-icon-whatsapp{
    color: #27D367;
    opacity: 1;
    padding-left: 25px;
  }

  .img-faq {
    margin: 10px!important;
    width: 280px;
    height: 304px;
    background-image: linear-gradient(to bottom, rgba(24,28,73, 0.52), rgba(33, 78, 226, 0.73)),
    url('../images/contact_faq_img.png');
    background-size: cover;
    opacity: 1;
    border-radius: 5px;
    margin: 0 auto;
  }

  .img-contact-us {
    margin: 10px!important;
    width: 280px;
    height: 304px;

    background-image:  linear-gradient(to bottom, rgba(24,28,73, 0.52), rgba(33, 78, 226, 0.73)),
    url('../images/contact_contact_us.png');
    background-size: cover;
    opacity: 1;
    border-radius: 5px;
    margin: 0 auto;
  }

  .text-banner{
    padding-left: 25px;
    padding-top: 150px;
    padding-right: 25px;
    text-align: left;
    font: normal normal bold 32px/48px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
}
