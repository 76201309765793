
$fontSize:14px;
$grid-columns:      12;
$grid-gutter-width: 30px;
$transitionDuration: 1s;
$borderRadius:12px;

$content-padding-xs: 15px;
$content-padding-md: 5%;
$content-padding-lg: 8%;
$content-padding-xl: 17%;
$content-padding-xxl: 20%;
$content-padding-xxxl: 25%;

$navBarheight:80px;
$pageMarginBottom:120px;
$navBarheightMobile:110px;
$menuFontSize: 16px;
$image-top-height: 600px;
$image-top-height-mobile: 400px;
$maxWidthContainner: 1100px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1200px,
  // Extra large screen / wide desktop
  xl: 1600px,
  // Extra large screen / wide desktop
  xxl: 2100px,
  xxxl: 2900px
);


$grid-max-breakpoints: (
  small: 959.98px,
);



