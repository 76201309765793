.layout-footer {
  background-color: var(--color-004a8d);
  color: white;
  padding-top: 80px;
  padding-bottom: 30px;
  width: 100%;
  font: normal normal 300 18px/24px Open Sans;
  letter-spacing: 0px;

  .footer-cont {
    padding-left: 100px;
    padding-right: 100px;

    @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .logo-footer-content {

    .social-icon {
      padding: 5px;
      a {
        img {
          height: 36px;
        }
      }
    }

    .white-separator {
      width: 100%;
      border-bottom: white solid 3px;
      margin-top: 40px;
      margin-bottom: 30px;
    }

    .social-icon:first-child {
      padding-left: 0px;
    }

    .social-icon:last-child {
      padding-right: 0px;
    }

  }

  .address-content {
    text-align: left;

    a {
      color: white;
    }

    .logo-link {
      cursor: pointer;

      .hotel-title {
        font-weight: bold;
        margin-left: 15px;
        width: 85px;
        font-size: 1rem;
        line-height: 1rem;
        word-break: normal
      }
    }

    .address {
      padding-top: 10px;
    }

    .contact {
      display: grid;
      margin-top: 20px;

      span {

        fa-icon {
          margin-right: 8px;
        }
      }

      .contact-icon {
        font-size: 18px;
      }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
      padding-top: 50px;
    }
  }

  .text-bottom {
    margin-top: 50px;
    color: white;
    text-align: center;
  }
}


.submenu-content {
  .grid {
    margin: 0px !important;
  }
  .submenu {
    display: grid;
    margin-right: 60px;

    .title {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 22px;
    }
    .item{
      margin-bottom: 10px;
      a{
        color: white;
      }
    }
  }


  // @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  //   padding-top: 50px;
  // }
}
