
.icon-containner {
  content: ' ';
  display: block;
  //min-width: 36px;
  min-height: 36px;
  height: 100%;
  width: 100%;
  margin: 0 !important;
  opacity: 0.6 !important;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
}

.icon-small{
  &:before {
    min-height: 24px !important;
    min-width: 24px !important;
  }

  &.no-border {
    border: 0px !important;
  }

}


.img-icon-not {
  background-color: var(--color-004a8d) !important;
  border: solid 1px  var(--color-7784ac) !important;
  color: white;
}

.img-icon-active {
  opacity: 1 !important;
  &:before {
    opacity: 1 !important;
  }
}


.btn-img-icon-active {
  opacity: 1 !important;
  background-color: white !important;
  border: solid 1px var(--color-004a8d) !important;

  &:before {
    opacity: 1 !important;
  }
}


.back-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/back.svg");
  }
  &.active {
    @extend .img-icon-active;
  }
}

.info-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/info.svg");
  }
  &.active {
    @extend .img-icon-active;
  }
}

.step01-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/step-01.svg");
  }
  &.active {
    @extend .img-icon-active;
  }
}

.step02-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/step-02.svg");
  }
  &.active {
    @extend .img-icon-active;
  }
}

.kid-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/kids.svg");
  }
  &.active {
    @extend .img-icon-active;
  }
}

.pet-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/pets.svg");
  }
  &.active {
    @extend .img-icon-active;
  }
}

.calendar-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/calendar.svg");
  }
  &.active {
    @extend .img-icon-active;
  }

}

.person-icon {
  @extend .icon-small;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/person.svg");
  }
  &.active {
    @extend .img-icon-active;
  }
}

.person-btn-icon {
  @extend .img-icon-not;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/person_white.svg");
    background-size: 80% !important;
  }
  &.active {
    @extend .btn-img-icon-active;
    &:before {
      background-image: url("../images/person.svg");
    }
  }
}


.kids-btn-icon {
  @extend .img-icon-not;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/kids_white.svg");
    background-size: 80% !important;
  }
  &.active {
    @extend .btn-img-icon-active;
    &:before {
      background-image: url("../images/kids.svg");
    }
  }
}

.kidsx2-btn-icon {
  @extend .img-icon-not;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/kids_x2_white.svg");
    background-size: 80% !important;
  }
  &.active {
    @extend .btn-img-icon-active;
    &:before {
      background-image: url("../images/kids_x2.svg");
    }
  }
}


.pet-btn-icon {
  @extend .img-icon-not;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/pet_white.svg");
    background-size: 80% !important;
  }
  &.active {
    @extend .btn-img-icon-active;
    &:before {
      background-image: url("../images/pet.svg");
    }
  }
}


.personx2-btn-icon {
  @extend .img-icon-not;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/person_white_x2.svg");
    background-size: 80% !important;
  }
  &.active {
    @extend .btn-img-icon-active;
    &:before {
      background-image: url("../images/person_x2.svg");
    }
  }
}

.treemore-btn-icon {
  @extend .img-icon-not;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/tree-more-white.svg");
    background-size: 80% !important;
  }
  &.active {
    @extend .btn-img-icon-active;
    &:before {
      background-image: url("../images/tree-more.svg");
    }
  }
}


.twomore-btn-icon {
  @extend .img-icon-not;
  &:before {
    @extend .icon-containner;
    background-image: url("../images/two-more-white.svg");
    background-size: 80% !important;
  }
  &.active {
    @extend .btn-img-icon-active;
    &:before {
      background-image: url("../images/two-more.svg");
    }
  }
}
