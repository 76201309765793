.landing-wrapper {
  width: 100%;

  .btn-reserva {
    text-align: center;
    border-radius: 0px;
    margin-top: -5px;
    vertical-align: middle;
  }
}

.image-top-animate {
  z-index: -1;
  // left: 0;
  width: 0px;
  height: 0px !important;
  transition-property: left, top, height, width, max-width;
  transition-duration: 1s;
  visibility: collapse;
}



.image-top {
  //height: $image-top-height;
  overflow: hidden;
  .video-box {
    position: relative;
    width: 100%;
    height: 100%;
    //height: $image-top-height;
    //padding-bottom: 56.25%;
    height: 200%;
    top: -50%;
    overflow: hidden;

    iframe {
      //width: 100%;
      height: 100%;
    }
  }

  .horizontal-gradient {
    z-index: 10;
    position: absolute;
    top: $navBarheight;
    width: 100%;
   // height: $image-top-height;
  }

  img {
    width: 100%;
    //height: $image-top-height;
    object-fit: cover;
  }

  .logo-brand {
    z-index: 11;
    position: absolute;
    top: 130px;
    padding: 30px;
    color: white;
    max-width: 70%;
    text-align: justify;


    img {
      height: 80px;
      width: 80px;
    }

    .sub-title {
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    }

    .btn {
      margin-top: 20px;
    }
  }
}


.preview-content {
  padding-bottom: 25px;
  //color: var(--color-7784ac);

  .preview-title {
    padding: 20px 0px;

    img {
      height: 62px;
      margin-right: 15px;
    }

    h1 {
      color: var(--color-004a8d);
      font: normal normal normal 32px/36px Open Sans;
      letter-spacing: 0px;
    }

    h2 {
      text-align: left;
      font: normal normal normal 41px/48px Open Sans;
      letter-spacing: 0px;
      color: #004A8D;
      opacity: 1;
    }
  }


  .preview-search {
    margin-bottom: 40px;

    h1 {
      color: var(--color-004a8d);
      font: normal normal normal 32px/36px Open Sans;
      letter-spacing: 0px;
    }

    .input-search {
      margin-top: 40px;

      .btn-search {
        border-radius: 0px !important;
        margin-left: 6px;
      }
    }

    .tag-info {
      color: var(--color-7784ac);
      text-decoration: underline;
      margin-top: 30px;

      span {
        font-style: italic;
        cursor: pointer;
      }
    }
  }

  .show-more-link {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    a {
      cursor: pointer;
      color: var(--color-7784ac);
    }
  }
}


.justify-content-center {
  justify-content: center !important;
}


.landing-content-fit {
  min-height: calc(100vh - $navBarheight - $pageMarginBottom) !important;
}


@media screen and (max-width: map-get($grid-breakpoints, md)) {


  .image-top {

    .video-box {
      position: relative;
      iframe {
        position: absolute;
        left: -25%;
        width: 150%;
        height: 100%;
      }
    }

    .horizontal-gradient {
      z-index: 10;
      position: absolute;
      top: $navBarheight;
      width: 100%;
      //height: $image-top-height-mobile;
    }

    img {
      width: 100%;
      //height: $image-top-height-mobile;
      object-fit: cover;
    }

    .logo-brand {
      max-width: 100%;
      text-align: justify;
      top: 100px;

    }
  }

  .responsive-center {
    width: 100%;
    padding-left: $content-padding-xs !important;
    padding-right: $content-padding-xs !important;
    transition: padding-left $transitionDuration;
  }

  .landing-wrapper {
    .responsive-center {
      width: 100%;
      padding-left: 0px !important;
      padding-right: 0px !important;
      transition: padding-left $transitionDuration;
    }
  }


  .logo-brand {
    margin-left: $content-padding-xs !important;
    max-width: auto !important;
    width: auto !important;
    //padding-right: $content-padding-xs !important;
  }


  .landing-content-fit {
    padding-left: 0 !important;
    padding-right: 0 !important
  }

  .page-content-padding {
    padding: 20px !important;
  }


  .preview-content {
    padding-left: $content-padding-xs !important;
    padding-right: $content-padding-xs !important;
  }

}

@media screen and (min-width: map-get($grid-breakpoints, md)) {

  .responsive-center {
    width: 100%;
    padding-left: $content-padding-md !important;
    padding-right: $content-padding-md !important;
    transition: padding-left $transitionDuration;
  }

}



@media screen and (min-width: map-get($grid-breakpoints, lg)) {

  .responsive-center {
    width: 100%;
    padding-left: $content-padding-lg !important;
    padding-right: $content-padding-lg !important;
    transition: padding-left $transitionDuration;
  }

}

@media screen and (min-width: map-get($grid-breakpoints, xl)) {

  .responsive-center {
    width: 100%;
    padding-left: $content-padding-xl !important;
    padding-right: $content-padding-xl !important;
    transition: padding-left $transitionDuration;
  }

}

@media screen and (min-width: map-get($grid-breakpoints, xxl)) {

  .responsive-center {
    padding-left: $content-padding-xxl !important;
    padding-right: $content-padding-xxl !important;
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    // margin-left: auto !important;
    // margin-right: auto !important;
    // width: $maxWidthContainner !important;
    transition: padding-left $transitionDuration;
  }

}


@media screen and (min-width: map-get($grid-breakpoints, xxxl)) {

  .responsive-center {
    padding-left: $content-padding-xxxl !important;
    padding-right: $content-padding-xxxl !important;
    transition: padding-left $transitionDuration;
  }

}
