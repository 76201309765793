.layout-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //padding: 8.6rem 2rem 2rem 2rem;
  padding-top: $navBarheight;
  transition: margin-left $transitionDuration;

  .site-wrapper {
    min-height: calc(80vh);
  }
}

.layout-main {
  flex: 1 1 auto;
}

.home-content {
  height: calc(100vh - $navBarheight);
  overflow: hidden !important;

  .image-top {
    .logo-brand {
      top: 250px - $navBarheight;
    }
    .horizontal-gradient {
      top: 0 !important
    }
  }


  .unit-image-content {
    height: 100%;
    cursor: pointer;
    border-left: solid 1px white;
    border-right: solid 1px white;
    overflow: hidden !important;

    .unit-image {
      height: calc(100vh - $navBarheight);
      width: 100%;
      object-fit: cover;
    }
  }

  .hover-content {
    position: absolute;
    bottom: 0;

    .logo-brand {
      position: absolute;
      bottom: 0;
      padding: 30px;
      color: white;
      font-size: 1.5rem;
      line-height: 1.5rem;
      max-width: 220px;

      img {
        height: 80px;
        width: 80px;
      }
    }

    .hover-opacity {
      z-index: 99;
      opacity: 0.75;
      height: 100%;
      width: 100%;

      background: transparent linear-gradient(180deg, var(--color-0130a4) 0%, var(--color-181c49) 100%) 0% 0% no-repeat padding-box;

      transition-property: opacity;
      transition-duration: 2s;

    }

    .hover-opacity:hover {
      background-color: transparent;
      animation: hoverUnitPhoto 0.5s linear forwards;
    }

    .hover-opacity:not( :hover) {
      animation: notHoverUnitPhoto 0.5s linear forwards;
    }
  }
}

.fade-hover {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  overflow: hidden;

}

@keyframes hoverUnitPhoto {
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
}

@keyframes notHoverUnitPhoto {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.75;
  }
}






@media only screen and (max-width: map-get($grid-breakpoints, md)) {

  .layout-main-container {
    padding-top: $navBarheightMobile;

  }

  .image-top {
    top: $navBarheightMobile + 4;

    .horizontal-gradient {
      top: $navBarheightMobile;
    }

  }

  .home-content {
    height: auto;
    overflow-y: hidden;

    background: linear-gradient(to right, rgba(0, 0, 0, 0.801), rgba(0, 0, 0, 0.438));

    .image-top {
      .logo-brand {
        top: 250px - $navBarheight;
      }

      .horizontal-gradient {
        top: 0 !important
      }
    }


    .unit-image-content {
      display: grid;
      grid-template: "container" 1fr;
      // place-items: center;
      // place-content: center;
      overflow: hidden;
      height: calc(100vh - 80px);
      border: 0;
      width: 100%;

      .unit-image {
        height: calc(100vh - 80px);
        width: 100%;
        object-fit: cover;
      }

      .hover-content-mob {
        z-index: 105;
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        place-content: center center;
        align-items: center;
        height: 100%;

        .logo-brand {
          flex-direction: column;
          box-sizing: border-box;
          display: flex;
          place-content: center;
          align-items: center;

          img {
            height: 90px;
          }

          p {
            margin-top: 20px;
            font-size: 22px;
            color: white;
          }

        }
      }

      .hover-opacity {
        height: 100%;
        width: 100%;
        z-index: 99;
        opacity: 0.75;
        //background: transparent linear-gradient(180deg, rgba(71, 71, 71, 0.685) 0%, rgb(20, 20, 20) 100%) 0% 0% no-repeat padding-box;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.801), rgba(0, 0, 0, 0.438));
      }

    }


    .unit-image-content>* {
      grid-area: container;
      max-width: 1000px;
    }



  }


}
