.trip_advisor-content{

    @media screen and (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }

    img{
      width: 120px;
      height: 68px;
    }

  }
