.testimonial-content {

  .testimonial_box {
    width: 100%;
    padding: 25px;
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BFC4CC;
    opacity: 1;
    margin-bottom: 15px;

    .author-field {
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      place-content: center flex-start;
      align-items: center;
      padding-bottom: 25px;
    }


    .testimonial-name {
      text-align: left;
      font: normal normal 600 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #004A8D;
      opacity: 1;
      margin: 0px !important;
    }

    .testimonial-ocuppation {
      text-align: left;
      font: normal normal normal 15px/24px Open Sans;
      font-weight: bold;
      letter-spacing: 0px;
      color: #484848;
      opacity: 1;
      margin: 0px !important;
    }

    .testimonial-description {
      text-align: left;
      font: normal normal normal 18px/24px Open Sans;
      letter-spacing: 0px;
      color: #484848;
      opacity: 1;
    }

    .img-author {
      width: 132px;
      height: 132px;
      background-size: cover;
      object-fit: cover;
      opacity: 1;
      border-radius: 50%;
      margin: 0 auto;
    }

    .row-author {
      padding-left: 35px;
    }
  }



  .testimonial_box_lab {

    font: normal normal bold 18px/24px Open Sans;
    color: #242424;

    .name {
      font: normal normal 600 23px/32px Open Sans;
      letter-spacing: 0px;
      color: #004A8D;
    }

    .image-box {
      border: 1px solid #C89633;
      border-radius: 15px;

      .img-box {
        background-color: #cccccc;
        margin-right: -10px;
        margin-bottom: -10px;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 15px;
        height: 240px;

        img {
          height: 240px;
          width: 100%;
          border-radius: 15px;
          object-fit: cover;
        }
      }

    }

  }

}
