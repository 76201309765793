
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  background-color: var(--color-004a8d);
  overflow-x: hidden;
  transition: width 0.5s !important;


}

.overlay .closebtn {
  color: white;
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  z-index: 99;
}


.p-dialog-mask {
  z-index: 999999 !important;
}



@media screen and (max-height: 450px) {
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
