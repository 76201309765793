.layout-topbar {
  position: fixed;

  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  background: var(--color-ffffff);
  background-color: var(--color-ffffff);
  transition: left $transitionDuration;
  align-items: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);
}




.top_navbar {
  height: $navBarheight;
  border-bottom: solid 3px var(--color-f6be00);
  width: 100%;
  background-color: var(--color-ffffff);

  @media only screen and (max-width: map-get($grid-breakpoints, md)) {
    height: $navBarheightMobile !important;

    .sk-menu {
      margin-right: 0;
      min-width: 80px !important;
    }
  }

  .sk-logo {
    margin-right: 20px;
    height: 56px !important;
    width: 120px !important;
  }

  .sk-menu {
    margin-right: 30px;
    min-width: 500px;
  }

  /* Navbar container */
  .navbar {
    overflow: hidden;
    background-color: var(--color-ffffff);
    display: flex;
    width: 100%;

    .horizontalmenu-content, .dropdown, .dropbtn {
      min-height: calc($navBarheight - 5px);
    }
  }

  .logo-separator {
    border-left: solid 2px var(--color-004a8d);
    margin-left: 20px;
    margin-right: 15px;
    height: 56px
  }

  .hotel-title {
    color: var(--color-004a8d);
    font-weight: 700;
    margin-left: 15px;
    width: 75px;
    font-size: 14px;
    line-height: $menuFontSize;;
    word-break:normal;

    @media only screen and (max-width: map-get($grid-breakpoints, md)) {
      margin-top: 8px !important;
      margin-left: 0px;
      text-align: center !important;
      text-transform: uppercase;
      font-weight: normal;
      width: 100px;
      font-size: 12px
    }


  }


  /* Links inside the navbar */
  .navbar .item-content a {
    float: left;
    font-size: $menuFontSize !important;
    color: var(--color-484848);
    font-weight: 600 !important;
    text-align: center;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
    font: inherit;
    &.sub-item {
      font-size: 18px !important;
      font-weight: normal !important;
    }

  }

  /* The dropdown container */
  .dropdown {
    overflow: hidden;
    width: auto !important;
    color: var(--color-484848);
  }

  /* Dropdown button */
  .dropdown .dropbtn {
    font-size: $menuFontSize !important;
    font-weight: 600 !important;
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--color-484848);
    padding-right: 8px;
    padding-left: 8px;
    background-color: var(--color-ffffff);
    /* Important for vertical align on mobile phones */
    margin: 0;
    /* Important for vertical align on mobile phones */
  }

  /* Add a red background color to navbar links on hover */
  .navbar a:hover,
  .dropdown:hover .dropbtn {
    color: var(--color-0130a4);
    ;
  }

  /* Dropdown content (hidden by default) */
  .dropdown-content {

    position: absolute;
    background-color: var(--color-ffffff);
    color: var(--color-484848);
    width: 100%;
    min-height: 280px;
    left: 0;
    top: calc($navBarheight - 10px);
    opacity: 0;
    z-index: -99;

    transform: translateY(-380px);
    //transform: scaleY(0);
    transition: transform .8s;

    padding-bottom: 50px;
  }

  /* Mega Menu header, if needed */
  .dropdown-content .header {
    background: var(--color-ffffff);
    padding: 16px;
    color: var(--color-484848);
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    visibility: visible;
    border-bottom: solid 3px var(--color-f6be00);
    transform: translateY(0px);
    opacity: 1;
    animation: fronte 7.0s linear forwards;
    //animation: menuOpacity 0.8s linear forwards;
  }

  .dropdown-content:hover {
    animation: fronte 3s linear forwards;
  }

  @keyframes fronte {
    from {
      z-index: -1;
    }

    to {
      z-index: 4;
    }
  }

  /* Create three equal columns that floats next to each other */
  .column {
    float: left;
    display: grid;
    // width: 33.33%;
    padding: 10px;
    background-color: white;
    h3{
      font-weight: 600 !important;
    }

    a {
      padding: 10px !important;
      padding-left: 2px !important;
      text-align: left !important;
      font-weight: normal !important;
    }

  }

  /* Style links inside the columns */
  .row a {
    float: none;
    color: var(--color-484848) !important;
    font-weight: normal !important;
    padding: 10px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  /* Add a background color on hover */
  .column a:hover {
    background-color: rgb(240, 240, 240);
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }








}




.layout-topbar-content {
  display: flex;
  width: 100%;
  background: var(--color-ffffff);
  background-color: var(--color-ffffff);
  align-items: stretch;
  padding-left: 3rem;
  padding-right: 3rem;


  .logo-content {
    display: flex;
    background-color: var(--color-ffffff);
  }

  .search-content {
    background-color: var(--color-ffffff);

  }

  .btn-reserva {
    font: normal normal normal 14px/14px Open Sans;
  }

  .logo-link {

    img {
      max-height: calc($navBarheight - 3px);
      @media only screen and (max-width: map-get($grid-breakpoints, md)) {
        max-height: calc($navBarheightMobile - 3px) !important;
        max-width: 128px !important;
      }
    }

    cursor: pointer;

  }

  .horizontal-menu {
    margin-left: 5px;
  }

  .menu-list {
    background-color: white !important;
    font-weight: bold !important;

    label {
      cursor: pointer;
    }
  }


  .topbar-bars-button {
    i {
      color: var(--color-484848);
    }
  }

  .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    color: var(--color-484848);
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;

    &:hover {
      color: var(--text-color);
      background-color: var(--surface-hover);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.3rem;
      margin-right: 3px;
    }

    img {
      height: 26px;
      width: 28px;
    }

    span {
      font-size: 1.6rem;
      //font-size: var(--font-size-18) !important;
      font-weight: bold !important;
      display: block;
    }
  }

}

.p-component-overlay-enter {
  z-index: 10 !important;
}



@media screen and (min-width: map-get($grid-breakpoints, xs)) {

  .p-sidebar-left {
    width: 100% !important;
  }

  .dropdown-content {
    width: 100%;
    padding: 0px !important;
    transition: margin-left $transitionDuration;
  }

  .layout-search-content {
    width: 98%;
    margin-left: 5px;
    padding: 0px !important;
  }

}
