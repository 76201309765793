

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--color-ffffff);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 3px;

  .menu-itens-container {
    padding-top: 20px;
  }

  .closebtn {
    float: right;
    cursor: pointer;
    font-size: 36px;
    margin-right: 5px;
    margin-left: 10px;
  }


}

.sidenav .top_navbar {
  border-bottom: solid 3px var(--color-004a8d);
}

.sidenav .top_navbar .sidenav-header {
  height: 100%;

  box-shadow: 0px 3px 5px rgba(0, 0, 0, .02), 0px 0px 2px rgba(0, 0, 0, .05), 0px 1px 4px rgba(0, 0, 0, .08);

  .logo-link {
    padding: 0;
    padding-left: 15px;
    display: flex;
    .hotel-title {
      font-weight: bold;
      margin-left: 15px;
      width: 85px;
      font-size: 1rem;
      line-height: 1rem;
      word-break: normal
    }
  }

}

.sidenav a {
  text-decoration: none;
  color: var(--color-484848);
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: var(--color-004a8d);;
}


.sidenav .rootlink {
  text-transform: uppercase;
  font-weight: bold;
}

.sidenav a, .dropdown-btn {
  padding: 6px 8px 10px 16px;
  text-decoration: none;
  font-size: 18px;
  color: var(--color-484848);;
  display: block;
  border: none;
  background: none;
  width:100%;
  text-align: left;
  cursor: pointer;
  outline: none;

  .active {
    background-color: green;
    color: var(--color-004a8d);;
  }

  fa-icon {
    float: right;
    font-size: 16px;
  }

}

.sidenav .separator {
  margin: 0px 8px 0px 16px;
  border-top: 1px solid #dee2e6;
}


/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
  color: var(--color-004a8d);;
}



.dropdown-container {
  padding-left: 8px;
  transition: 0.8s;
}




@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
