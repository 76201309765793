$cardheight: 460px;


.tariffs-filter-content {
  background-color: var(--color-004a8d);
  color: white;
  transition: height 1s;
  height: 190px;

  &.in-home {
    background-color: transparent !important;
    border-top: solid 5px var(--color-004a8d) !important;

    @media only screen and (max-width: map-get($grid-max-breakpoints, small)) {
      border-top: unset !important;
    }

  }

  .fields-content {
    background-color: var(--color-004a8d);
    padding-top: 40px;
    display: flex;
    height: 190px;
    transition: height 1s;
    position: relative;

    .last-field {
      position: absolute !important;
      right: 20px;
    }

    &.mob {
      flex-direction: column;
      place-content: center flex-start;
      align-items: center;
      transition: height 1s;
      height: 100px !important;

      .field {
        width: auto !important;
        margin-bottom: 30px;
        text-align: center !important;

        input {
          width: 210px !important;
        }
      }

      .last-field {
        margin-top: -30px;
        position: relative !important;
        right: unset !important
      }
    }

    &.in-home {
      padding-left: 10px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }



  }

  .filter-details {
    position: relative;
    margin-top: 0px;
    opacity: 0;
    transition: opacity 2s;
    display: grid;
    width: 149px;

    .label {
      font-size: 14px;
      width: 100%;
      margin-bottom: 5px;
    }

    input {
      width: 149px;
      padding-left: 50px;
      padding-right: 50px;
      text-align: center;

    }

    .field-btn {
      position: relative;
      display: inline-block;
      height: 53px !important;
      width: 149px;
      margin-top: 13px !important;
      display: block;
      background-color: var(--color-004a8d);
      cursor: pointer;
      border: 1px solid var(--color-7784ac);
      text-align: center;
      padding: 12px;
    }

    .icon-minus {
      position: absolute;
      left: 17px;
      font-size: 22px;
      top: 25px;
      cursor: pointer;
    }

    .icon-plus {
      position: absolute;
      right: 17px;
      font-size: 22px;
      top: 25px;
      cursor: pointer;
    }
  }


}

.tariffs-filter-content {
  //.fields-content:hover {

  .btn-open-nav {
    padding: 30px;
    display: flex;
    flex-direction: column;
    place-content: center flex-start;
    align-items: center;

  }

  &.opened {
    height: 240px;

    .filter-details {
      display: grid;
      opacity: 1;
    }

    .tariffs-filter-content {
      height: 240px;
    }
  }


}

.tariffs-filter-content:hover {
  //.tariffs-filter-content:hover {

  &.opened {
    height: 240px;

    .fields-content {
      height: 240px;

      .filter-details {
        display: grid;
        opacity: 1;
      }
    }

  }
}





.tariff-page-content {
  padding-top: 50px;

  @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    padding-top: 30px !important;
  }

  .step-title {
    padding-bottom: 30px;
    font-weight: 600;

    span {
      margin-left: 8px;
    }
  }

  .back-link {
    cursor: pointer;
    color: var(--color-004a8d);
    margin-top: 30px;

    .back-icon:before {
      min-height: 28px !important;
      min-width: 28px !important;
      margin-right: 10px;
    }
  }

  .card-content {

    .card {
      cursor: unset !important;
      display: flex;
      place-content: flex-start;
      align-items: flex-start;
      min-height: $cardheight;
      max-height: $cardheight;
      box-shadow: 4px 4px 8px #4848483D !important;
      padding: 0px;
      margin: 10px !important;
      border: solid 2px var(--color-7784ac);
      border-radius: 8px;

      &.selected {
        border: solid 2.2px var(--color-f6be00) !important;
      }

      .image-content {
        width: 40%;
      }

      .text-content {
        width: 60%;
        padding: 25px;
        display: grid;

        .title {
          color: var(--color-242424);
          font-weight: 600;
          margin: 0px;
          margin-bottom: 4px;
          height: 26px;
          overflow: hidden;
          font-size: 22px;
        }

        .priceItens-content {
          min-height: 140px;
        }

        .sub-title {
          font-size: 16px !important;

          @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
            font-size: 14px !important;
          }

          color: var(--color-004a8d);
          font-weight: 600;
          margin: 0px;
        }

        .img-icon {
          height: 24px;
          width: 24px;
        }

        .icon-field {
          @extend .flex-vcenter;
          font-size: 14px
        }

        .option-field {
          @extend .flex-vtop;
          cursor: pointer;

          input[type=radio] {
            width: 18px;
            height: 18px;
          }
        }


        .values {
          margin-top: -3px;
          display: grid;

          .title-value {
            font-size: 16px;
          }

          .under-value {
            text-decoration: line-through;
            color: var(--color-7784ac);
          }

          .active-value {
            font-size: 36px;
            color: var(--color-004a8d);
          }

          fa-icon {
            color: var(--color-004a8d);
            margin-left: 5px;
            height: 16px;
            width: 16px;
            font-size: 16px;
          }
        }


        @media only screen and (max-width: map-get($grid-breakpoints, md)) {
          padding-left: 15px;
          padding-right: 5px;

          .icon-field {
            margin-top: 8px !important;
          }

          .option-field {
            margin-top: 30px !important;
          }

          .values {
            .active-value {
              font-size: 26px;
            }
          }

        }






      }
    }

  }



  .info-card {
    padding: 20px;
    color: var(--color-242424);
    font: normal normal normal 18px/24px Open Sans;

    b {
      font-weight: 600;
    }

    a {
      color: var(--color-004a8d);
      text-decoration: underline;
    }
  }

}


.tariff-sended-content {
  padding-top: 90px;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;

  img {
    height: 280px;

    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
      height: 200px !important;
    }
  }

  .text {
    text-align: center;
    width: 60%;
    font-weight: bold;

    .title {
      font-size: 42px;
      line-height: 40px;
      color: var(--color-004a8d);
    }

    span {
      margin-top: 30px;
    }
  }

}


@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .field {
    width: 100%;
  }

  input {
    width: 100% !important;
  }

  .fields-content {
    padding-left: 10%;
    padding-right: 10%;
  }

  .tariff-page-content {

    .card-content {

      .card {

        display: flex;
        flex-direction: column;

        min-height: unset;
        max-height: unset;

        .image-content {
          width: 100%;
        }

        .text-content {
          width: 100%;

          .priceItens-content {
            min-height: unset;
          }
        }
      }
    }


    .galleryTariffCards {
      width: 100% !important;
      height: 150px !important;
      border-top-left-radius: 6px !important;
      border-top-right-radius: 6px !important;
      border-bottom-left-radius: 0px !important;


    }



  }
}




.galleryTariffCards {
  width: 100% !important;
  height: calc($cardheight - 2px);
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;

  // @media only screen and (max-width: map-get($grid-breakpoints, md)) {
  //   height: 250px !important;
  // }


}
