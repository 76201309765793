@import 'aos/dist/aos.css';
@import "./assets/styles/variables";
@import "./assets/styles/fxLayout";
@import "./assets/styles/mixins";
@import "./assets/styles/responsive";
@import "./assets/styles/header";
@import "./assets/styles/footer";
@import "./assets/styles/content";
@import "./assets/styles/main";
@import "./assets/styles/typography";
@import "./assets/styles/testimonial";
@import "./assets/styles/trip_advisor";
@import "./assets/styles/components";
@import "./assets/styles/dropdown";
@import "./assets/styles/page";
@import "./assets/styles/contact";
@import "./assets/styles/faq";
@import "./assets/styles/@highlight_style";
@import "./assets/styles/sidenav";
@import "./assets/styles/tariff";
@import "./assets/styles/images";
@import "./assets/styles/overlay";
@import "./assets/styles/dynamic_components";
@import "./assets/styles/startup";
@import "./assets/styles/reservation_contact";

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: $fontSize;
}




:root {

  /* Colors: */
  --color-004a8d: #004A8D;
  --color-242424: #242424;
  --color-f5f5f5: #F5F5F5;
  --color-ffffff: #FFFFFF;
  --color-7784ac: #7784AC;
  --color-bfc4cc: #BFC4CC;
  --color-e5e5e5: #E5E5E5;
  --color-0130a4: #0130A4;
  --color-4839b3: #4839B3;
  --color-4eb0fa: #4EB0FA;
  --color-4f7afb: #4F7AFB;
  --color-484848: #484848;
  --color-f91567: #F91567;
  --color-f29100: #F29100;
  --color-c89633: #C89633;
  --color-fc4629: #FC4629;
  --color-181c49: #181C49;
  --color-009ba9: #009BA9;
  --color-f6be00: #F6BE00;
  --color-06777f: #06777F;
  --color-d8f912: #D8F912;
  --color-a3cb00: #A3CB00;

  /* Font/text values */
  --font-family-open-sans: Open Sans;
  --font-style-normal: normal;
  --font-weight-300: 300px;
  --font-weight-normal: normal;
  --font-weight-bold: bold;
  --font-size-18: 18px;
  --font-size-41: 41px;
  --font-size-47: 47px;
  --character-spacing-0: 0px;
  --line-spacing-24: 24px;
  --line-spacing-48: 48px;
  --text-transform-uppercase: uppercase;
}

/* Character Styles */
.open-sans-—-18pt {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-300);
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-242424);
  text-transform: var(--text-transform-uppercase);
}

.open-sans-—-41pt {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-41);
  line-height: var(--line-spacing-48);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-004a8d);
}

.open-sans-—-18pt {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-18);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-484848);
}

.open-sans-—-47pt {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-47);
  line-height: var(--line-spacing-48);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-242424);
}



body {
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font: normal normal 300 18px/24px Open Sans;
  font-family: var(--font-family-open-sans) !important;

  font-weight: var(--font-weight-600) !important;
  letter-spacing: var(--character-spacing-0);
  color: var(--color-242424);
  text-align: left;
  letter-spacing: 0px;
  color: var(--color-484848);
  opacity: 1;
}



.layout-wrapper {
  min-height: 100vh;
  overflow-x: hidden;
}

.w-full {
  width: 100% !important;
}

.rtl-align {
  text-align: right;
}

svg.material-icons {
  height: 24px;
  width: auto;
}

svg.material-icons:not(:last-child) {
  margin-right: 8px;
}

.card svg.material-icons path {
  fill: #888;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

.card {
  // border-radius: 4px;
  box-shadow: 0px 3px 6px #4848483D;
  background-color: #ffffff;
  min-height: 80px;
  display: block;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  line-height: 24px;
  margin: 1rem;

  .card-image{
    object-fit: cover;
    width: 100%;
  }

  .card-content {
    padding: 20px;
    font-weight: bold;
  }

  .card-bottom {
    padding: 0px 20px 25px 20px;
  }
}

.card:not(:last-child) {
  margin-right: 0;
}

.card.card-small {
  height: 16px;
  width: 168px;
}

.card:not(.highlight-card) {
  cursor: pointer;
}

.card:not(.highlight-card):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.card:not(.highlight-card):hover .material-icons path {
  fill: rgb(105, 103, 103);
}

.card.highlight-card {
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  border: none;
  width: auto;
  min-width: 30%;
  position: relative;
}

.card.card.highlight-card span {
  margin-left: 60px;
}

.card-simple {
  @extend .card;
  box-shadow: none !important;
  cursor: default !important;
  border: solid 1.2px var(--color-e5e5e5) !important;
}


.flex-vcenter {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}

.flex-vtop {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
}
