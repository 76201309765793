

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: map-get($grid-breakpoints, xs)) {

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, sm)) {

}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, md)) {

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, lg)) {

}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, xl)) {

}
