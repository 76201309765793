.reservation-contact-card {
  border: 1px solid #BFC4CC;
  padding: 30px;
}


@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .reservation-contact-card {
    width: 100%;
  }
}
