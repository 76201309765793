.faq-content{
  .faq_text_question{
    text-align: left;
    font: normal normal 600 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #484848;
    opacity: 1;
    float: left;
  }

  .faq_text_answer{
    text-align: justify;
    font: normal normal normal 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #484848;
    opacity: 1;
    float: left;
  }

  .faq_toggle{
    float: right;
  }

  .faq_answer{
    padding: 10px;
    width: 100%;
  }
  .faq_question{
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }
  .faq_line{
    margin: 10px;
    width: 100%;
    height: 5px;
    border-bottom: 1px solid #484848 ;
    margin-bottom: 30px;
  }
}
