.fc .fc-bg-event {
  cursor: pointer !important;
}

.p-component {
  font-size: 18px !important;
  font: normal normal 300 18px/24px Open Sans;
  font-family: var(--font-family-open-sans) !important;
}

.p-datepicker table {
  font-size: 18px !important;
  font: normal normal 300 18px/24px Open Sans;
  font-family: var(--font-family-open-sans) !important;
}

.p-calendar .p-datepicker {
  @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    width: calc(100vw - 50px);
  }
}

.g-nav {
  background: white;
  border-radius: 49%;
  width: 40px !important;
  height: 40px !important;
  padding: 10px;
  bottom: 20px;
  top: unset !important;
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: auto;

  svg {
    display: none;
  }
}

.g-nav-next {
  @extend .g-nav;
  right: 15% !important;
  background-image: url("../images/next.svg");
}

.g-nav-prev {
  @extend .g-nav;
  left: 15% !important;
  background-image: url("../images/next.svg");
  transform: rotate(180);
}

gallery-core[dotsPosition="bottom"] gallery-dots {
  bottom: 32px !important;
}

.carousel-content {
  min-height: 480px;
}

.gallery-comp {
  background-color: transparent !important;
  cursor: pointer !important;
}

.card-news {
  font-size: 18px;

  .card-image {
    img {
      height: 180px;
      width: 100%;
    }
  }

  .card-content {
    height: 120px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }

  .card-bottom {
    display: flex;
    justify-content: space-between;

    .area-content {
      display: flex;
      flex-direction: column;

      .btn-area {
        font: normal normal normal 12px/12px Open Sans !important;
        padding: 8px;
        margin-top: 2px;
        margin-bottom: 2px;
        min-height: 28px !important;
      }
    }

    h4 {
      font-weight: bold;
      color: var(--color-004a8d);
    }
  }
}

.card-events {
  text-align: left;

  .card-image {
    height: 180px;

    img {
      height: 180px;
      width: 100%;
      //object-fit: fill;
    }
  }

  .card-content {
    width: 100%;
    height: 330px;
    min-height: 330px;
    color: #484848;

    .c-title {
      font-size: 18px;
      letter-spacing: 0px;
      color: var(--color-242424);
      overflow: hidden;
      text-overflow: ellipsis !important;
      height: 48px;

      word-break: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .c-title-blue {
      font-size: 18px;
      color: var(--color-004a8d);
    }

    .local {
      color: var(--color-242424);
      font-weight: normal;
      padding-top: 10px;
      padding-bottom: 10px;

      span {
        word-break: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }


      img {
        margin-left: -5px;
        margin-right: 5px;
      }
    }

    .c-resume {
      color: var(--color-242424);
      font-weight: 700 !important;
      max-height: 146px;
      font-size: 16px;
      font-weight: normal;
      text-align: left;

      word-break: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;

    }
  }
}

.galleryDetails {
  width: 100% !important;
  height: 600px !important;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (min-width: map-get($grid-breakpoints, xs)) {
    height: 350px !important;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: map-get($grid-breakpoints, sm)) {
    height: 350px !important;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: map-get($grid-breakpoints, md)) {
    height: 480px !important;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: map-get($grid-breakpoints, lg)) {
    height: 600px !important;
  }
}

.card-highlight-resume {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 50px !important;

  .highlight-resume-content {
    .image {
      app-image-gallery {
        .gallery {
          width: 100% !important;
          height: 300px !important;
        }
      }
    }
  }
}

.highlight-resume-content {
  display: flex;
  width: 100%;
  padding-bottom: 50px;

  youtube-embed {
    iframe {
      width: 100% !important;
    }
  }

  .image {
    app-image-gallery {
      .gallery {
        width: 100% !important;
        height: 380px !important;
      }
    }
  }

  .content {
    color: var(--color-242424);
    padding-left: 30px;
    padding-right: 30px;
    font: normal normal 300 18px/24px Open Sans;

    .title {
      text-transform: uppercase;
    }

    .sub-title {
      color: var(--color-004a8d);
      font-size: 32px;
      line-height: 34px;
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .description {
      font-size: 18px;
      text-align: justify !important;
    }

    .btn {
      margin-top: 40px;
    }
  }

  .rtl-align {
    text-align: left !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

.highlight-service-content {
  width: 100%;
  //min-height: 270px;
  background-color: var(--color-004a8d);

  .content {
    height: auto !important;
    min-height: auto !important;
    padding: 22px;
    display: grid;
    align-content: center;
    text-align: center;

    .title {
      padding-bottom: 30px;
      color: var(--color-e5e5e5);
      font: normal normal 100 18px/24px "Open Sans";
    }

    .services {

      @media only screen and (max-width: map-get($grid-breakpoints, md)) {
         padding: 20px;
      }


      .service {
        height: 90px;
        width: 200px;
        max-width: 200px;
        display: flex;
        padding-right: 10px;

        img {
          object-fit: fill;
        }

        .text {
          padding: 10px;
          color: var(--color-ffffff);
          text-align: left;
          font-size: 14px;
        }
      }
    }
  }
}

.highlight-cads-content {
  width: 100%;

  &.border {
    border: 1px solid #bfc4cc;
  }

  .title-info {
    padding-top: 50px;
    padding-bottom: 25px;

    .title {
      text-transform: uppercase;
    }

    .sub-title {
      color: var(--color-004a8d);
      font-size: 32px;
      line-height: 34px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .description {
      font-size: 18px;
      text-align: justify !important;
    }
  }

  .mobile-cards {
    overflow-x: scroll;
    padding-right: 50px !important;

    .mobile-cards-containner {
      padding-right: 50px;
      display: -webkit-inline-box;

      .card-border {
        padding-right: 18px;
        padding-left: 4px;

        .card {
          width: 280px;
        }
      }
    }
  }

  .cards {
    &.grid {
      margin-right: -20px !important;
      margin-left: -20px !important;
      margin-top: -20px !important;
    }

    .card {
      font-size: 16px;
      border-radius: 8px;

      .card-image {
        height: 340px;
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .card-content {
        height: 90px;
        font-weight: bold;
        color: var(--color-004a8d);
      }
    }
  }

  .box-circle {
  }

  .circle-container {
    padding-bottom: 30px;

    .image-circle {
      // width: 250px;
      // height: 250px;
      // border-radius: 50%;
      padding: 10px;
    }

    .details {
      font-size: 18px;
      padding-left: 12px;
      padding-right: 12px;
      text-align: center !important;
    }
  }
}

.btn {
  background-color: var(--color-004a8d);
  color: #ffffff;
  text-transform: uppercase;
  font: normal normal normal 18px/22px Open Sans;
  letter-spacing: 0px;
  border-radius: 4px;
  opacity: 1;
  min-height: 40px;
  border: 0;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  cursor: pointer;
  display: grid;
  align-items: center !important;
}

.btn:hover {
  background-color: var(--color-f6be00);
  color: var(--color-004a8d);
}

.btn-area {
  border-radius: 4px;
}

button:disabled {
  opacity: 0.4 !important;
}

.p-datepicker-month:disabled {
  opacity: 1 !important;
}

.p-datepicker-year:disabled {
  opacity: 1 !important;
}

.btn-icon {
  background: transparent;
  color: var(--color-7784ac);
  padding: 0px !important;
  border: 0 !important;
  cursor: pointer;

  span {
    margin-left: 3px;
  }
}

.horizontal-gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.411), rgba(0, 0, 0, 0));
}

.skeleton-loader:empty {
  width: 100%;
  height: 15px;
  display: block;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    lightgray;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

.sk-square {
  height: 70px !important;
  width: 70px !important;
  border-radius: 8px !important;
  margin: 5px;
}

.sk-rectangle {
  height: 50px !important;
  width: 100% !important;
  border-radius: 8px !important;
  margin: 5px !important;
  margin-bottom: 10px !important;
}

@keyframes shine {
  to {
    background-position: 100% 0;
  }
}

.field {
  margin-bottom: 15px;
}

input,
textarea,
.p-dropdown {
  width: 100%;
  padding: 12px 10px;
  margin: 0;
  box-sizing: border-box;
  color: var(--color-7784ac);
  border: 1px solid var(--color-e5e5e5);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  font-size: 18px;
  line-height: 1.42857143 !important;
  font-family: var(--font-family-open-sans) !important;
}

.p-dropdown .p-dropdown-label {
  color: var(--color-7784ac) !important;
  font-size: 18px;
}

input:focus {
  border: 1px solid var(--color-004a8d) !important;
}

textarea:focus {
  border: 1px solid var(--color-004a8d) !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid var(--color-004a8d) !important;
  box-sizing: border-box;
  box-shadow: none !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--color-7784ac) !important;
  opacity: 1 !important;
}

input::placeholder,
textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-7784ac);
  opacity: 1;
  /* Firefox */
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-7784ac);
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-7784ac);
}

.form-content {
  border: 1px solid #bfc4cc;
  padding: 30px;

  .field {
    label {
      font-weight: bold;
    }

    input,
    textarea {
      color: var(--color-242424);
      border: 1.2px solid var(--color-e5e5e5);
    }

    .p-dropdown {
      color: var(--color-242424);

      .p-focus {
        border: 1.2px solid var(--color-e5e5e5) !important;
      }
    }

    input,
    .p-dropdown,
    textarea::placeholder {
      color: var(--color-242424);
      opacity: 1;
    }
  }
}

.field {
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  display: grid;

  &.disabled {
    opacity: 0.8;
  }

  .lb {
    display: inherit;
    align-items: start !important;
    height: 30px;
    line-height: 14px;

    label {
      font-size: 12px;
    }
  }

  .inputField {
    position: relative;

    .icon {
      position: absolute;
      z-index: 10;
      bottom: 12px;
      // top: 28px;
      left: 5px;
    }

    input {
      height: 50px;
      margin-top: 3px;
      padding-left: 30px;
      color: var(--color-242424);
      width: 140px;
      cursor: pointer;
    }

    input::placeholder {
      color: var(--color-242424);
      opacity: 1;
    }

    input:-ms-input-placeholder {
      color: var(--color-242424);
    }

    input::-ms-input-placeholder {
      color: var(--color-242424);
    }

    input[type="date"] {
      position: relative;
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      @extend .img-icon;
      color: transparent;
      background: none;
      cursor: pointer;
      z-index: 1;
      background-image: url("../images/calendar.svg");
      background-size: 60%;
      position: absolute;
      left: 0;
      top: 13px;
      margin-left: 5px;
      bottom: 8px;
      height: 36px;
      width: 36px;
      background-repeat: no-repeat, repeat;

      @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        display: none;
      }
    }

    .field-btn {
      position: relative;
      display: inline-block;
      height: 50px !important;
      width: 43px !important;
      margin-top: 3px !important;
      display: block;
      background-color: white;
      cursor: pointer;
      border: 1px solid var(--color-7784ac);
    }

    .field-btn .tooltiptext {
      font-size: 12px;
      visibility: hidden;
      background-color: white;
      color: var(--color-004a8d);
      border: 1px solid var(--color-7784ac);
      width: 200%;
      text-align: center;
      border-radius: 6px;
      padding: 4px 0;
      position: absolute;
      z-index: 1;
      bottom: -38px;
      left: 50%;
      margin-left: -100%;
      opacity: 1 !important;
    }

    .field-btn:hover .tooltiptext {
      visibility: visible;
    }

    .field-btn .tooltiptext::before {
      content: " ";
      opacity: 1 !important;
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }

    .search-btn {
      width: auto !important;
      background-color: var(--color-004a8d) !important;
      color: white !important;
      border: 1px solid white;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 4px;
      font-size: 14px !important;

      @media only screen and (max-width: map-get($grid-breakpoints, xl)) {
        font-size: 14px !important;
      }
    }

    .search-btn:hover {
      background-color: var(--color-f6be00) !important;
      color: var(--color-004a8d) !important;
    }
  }
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #fff;
  min-width: 250px;
  max-width: 300px;
  box-shadow: 0 3px 30px rgb(33 33 33 / 30%);
  transition: opacity 0.1s ease-out;
  border: solid 3px var(--color-f6be00);
  border-radius: 0 !important;
}

.introjs-arrow.top {
  top: -13px !important;
  left: 10px;
  border-bottom-color: var(--color-f6be00) !important;
}

.introjs-button {
  background: var(--color-004a8d) 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  font: normal normal normal 16px Open Sans !important;
  letter-spacing: 0px !important;
  border-radius: 0px !important;
  opacity: 1;
  min-height: 20px !important;
  border: 0 !important;
  padding: 1.2rem !important;
  cursor: pointer;
  text-shadow: none !important;
}

.toastr {
  pointer-events: none !important;
}

.p-overlaypanel {
  z-index: 999999 !important;
}

.p-datepicker {
  .p-disabled {
    opacity: 0.2 !important;
  }
}

.p-datepicker table tbody td > span {
  font-weight: 600 !important;
}

.p-datepicker table td > span.p-highlight {
  color: #ffffff !important;
  background: #004a8d !important;
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  image-comp {
    width: 100% !important;

    img {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
  app-event-card {
    width: 100% !important;
  }
  app-newsletter-card {
    width: 100% !important;
  }
}
