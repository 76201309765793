
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  margin-top: -1rem;
}


.fullscreen {
  z-index: 9999 !important;
  position: fixed;
  left: 0 !important;
  top: $navBarheight + 3;
  height: $image-top-height !important;
  width: 100% !important;
  visibility: visible !important;

  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100% !important;
}

.hide-units {
  height: 30% !important;
  min-height: 30% !important;
  top: 0px;
}

.fade {
  opacity: 0.98;
}


.max-opacity {
  opacity: 0.92 !important;
  background-color: rgba(0, 0, 0, 0.738) !important;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: map-get($grid-breakpoints, xs)) {

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, sm)) {

}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, md)) {

}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, lg)) {

}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: map-get($grid-breakpoints, xl)) {

}
